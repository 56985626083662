@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #__next {
    @apply flex flex-col h-[100vh] bg-white;
  }

  html {
    scroll-padding-top: 5rem;
  }

  html,
  body {
    @apply h-full;
  }
}

:root {
  --progress-color: #470eb2;
}

.before-after-slider__delimiter {
  box-sizing: content-box;
  border-left: 2px solid #470eb2;
  border-right: 2px solid #470eb2;
}

.before-after-slider__delimiter-icon {
  box-sizing: content-box;
  border: 2px solid #470eb2;
}

#nprogress .bar {
  background: var(--progress-color);
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--progress-color), 0 0 5px var(--progress-color);
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  bottom: 1.5rem;
}

.flickity-page-dots .dot {
  display: inline-block;
  opacity: 0.5;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border-radius: 9999px;
  background-color: rgb(148, 163, 184);
}

.flickity-page-dots .dot.is-selected {
  background-color: rgb(30, 41, 59);
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  width: 44px;
  height: 44px;
  border-radius: 9999px;
  color: rgb(31, 41, 55);
}

.flickity-prev-next-button.next {
  right: 0.75rem;
}

.flickity-prev-next-button.previous {
  left: 0.75rem;
}

.flickity-button-icon {
  position: absolute;
  fill: currentColor;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
